








































































import { Vue, Component, Prop } from "vue-property-decorator";
import AddressUtil from "@/utilities/AddressUtil";
import CountryService from "@/services/CountryService";
import StateService from "@/services/StateService";
import CityService from "@/services/CityService";
import CommonUtil from "@/utilities/CommonUtil";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { CompanyInfoProperties } from "./CompanyInfoView.vue";

class CompanyAddressDialogProperties {
    visible: boolean = false;
    isNew: boolean = false;
    company: any = {};
    address: any = { city: { state: { country: {} } } };
}

export { CompanyAddressDialogProperties };

@Component({
	data: () => ({
		loadingCountries: false,
		loadingStates: false,
		loadingCities: false,
		countries: [],
		states: [],
        cities: [],
        cityId: null,
        stateId: null,
        countryId: null
	})
})
export default class CompanyAddressDialogView extends Vue {
    @Prop({ default: new CompanyAddressDialogProperties() })
    private properties: CompanyAddressDialogProperties;
	@Prop() private companyInfo: CompanyInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
	private countryService = new CountryService();
	private stateService = new StateService();
	private cityService = new CityService();

    public get title() {
        const isNew = this.properties.isNew;
        const t = this.$t('title.company-address');
        return t + ' - ' + (isNew ? this.$t('title.create') : this.$t('title.modify'));
    }

    public get address() {
        return this.properties.address;
    }

	public created() {
        const city = this.address.city;
        const state = city ? city.state : null;
        const country = state ? state.country : null;

        this.$data.cityId = city ? city.id : null;
        this.$data.stateId = state ? state.id : null;
        this.$data.countryId = country ? country.id : null;

		this.loadCountries();
		this.loadStates();
		this.loadCities();
	}

	public async loadCountries() {
		this.$data.loadingCountries = true;
		
		try {
			const r = await this.countryService.get();
			const countries = r.data.countries;
			this.$data.countries = countries.sort((lhs, rhs) => rhs.id - lhs.id);
		} catch (e) {
			this.$data.countries = [];
		} finally {
			this.$data.loadingCountries = false;
		}
	}
	
	public async loadStates() {
		this.$data.loadingStates = true;
		
		try {
            const countryId = this.$data.countryId;
			if (countryId) {
				const r = await this.stateService.get({ countryId: countryId });
				const states = r.data.states;
				this.$data.states = states.sort((lhs, rhs) => rhs.id - lhs.id);
			} else {
                this.$data.states = [];
                this.$data.stateId = null;
            }
		} catch (e) {
			this.$data.states = [];
		} finally {
			this.$data.loadingStates = false;
		}
	}
	
	public async loadCities() {
		this.$data.loadingCities = true;
	
		try {
            const stateId = this.$data.stateId;
			if (stateId) {
				const r = await this.cityService.get({ stateId: stateId });
				const cities = r.data.cities;
				this.$data.cities = cities.sort((lhs, rhs) => rhs.id - lhs.id);
			} else {
                this.$data.cities = [];
                this.$data.cityId = null;
			}
		} catch (e) {
			this.$data.cities = [];
		} finally {
			this.$data.loadingCities = false;
		}
	}
	
	public countryChanged() {
		this.loadStates();
	}
	
	public stateChanged() {
		this.loadCities();
	}

    public close() {
        this.properties.visible = false;
    }
	
    public submit() {
        const addresses: any[] = this.properties.company.addresses;
        const cities: any[] = this.$data.cities;
        const states: any[] = this.$data.states;
        const countries: any[] = this.$data.countries;
        const cityId = this.$data.cityId;
        const stateId = this.$data.stateId;
        const countryId = this.$data.countryId;

        const address = this.properties.address;
        
        var city = cities.find(x => x.id === cityId);
        var state = states.find(x => x.id === stateId);
        var country = countries.find(x => x.id === countryId);

        city = city ? CommonUtil.clone(city) : null;
        state = state ? CommonUtil.clone(state) : null;
        country = country ? CommonUtil.clone(country) : null;

        address.city = city;
        address.cityId = city ? city.id : null;
        if (city) city.state = state;
        if (state) state.country = country;
        address.full = AddressUtil.build(address);
        
        if (!address.city) {
            var m = this.$t('message.address-city-required');
            AlertDialogProperties.error(this.alertDialog, null, m);
            this.alertDialog.visible = true;
            return;
        }

        if (this.properties.isNew) {
            while (true) {
                var tempId = Math.random();
                if (!addresses.find(x => x.tempId === tempId)) {
                    address.tempId = tempId;
                    break;
                }
            }

            addresses.push(address);
            this.properties.visible = false;
        } else {
            for (var i = 0; i < addresses.length; i++) {
                if (addresses[i].tempId === address.tempId) {
                    Vue.set(addresses, i, address);
					break;
                }
            }
            this.properties.visible = false;
        }

        if (!address.printing) {
			const printing = addresses.some(x => x.printing);
            if (!printing) {
				address.printing = false;
			}
        }
        if (address.printing) {
			this.companyInfo.events.fire('printing-changed', address);
        }
    }
}
