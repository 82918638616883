
























































import { Vue, Component } from "vue-property-decorator";
import { EditorControlProperties } from "@/components/shared/EditorControlView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { CompanyAddressDialogProperties } from "./CompanyAddressDialogView.vue";
import EditorControlView from "@/components/shared/EditorControlView.vue";
import EventManager from "@/utilities/EventManager";
import CompanyService from "@/services/CompanyService";
import CurrencyService from "@/services/CurrencyService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import AddressUtil from "@/utilities/AddressUtil";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const CompanyMainView = () => import("./CompanyMainView.vue");
const CompanyAddressView = () => import("./CompanyAddressView.vue");
const CompanyAddressDialogView = () => import("./CompanyAddressDialogView.vue");

class CompanyInfoProperties {
	company: any = {};
	events = new EventManager();
}

export { CompanyInfoProperties };

@Component({
    components: { 
		EditorControlView, 
		AlertDialogView, 
		CompanyMainView,
		CompanyAddressView, 
		CompanyAddressDialogView 
	},
    data: () => ({
		tab: null,
        saving: false
    })
})
export default class ManagementCompanyInfoView extends Vue {
	private properties = new CompanyInfoProperties();
    private editorControl = new EditorControlProperties();
    private alertDialog = new AlertDialogProperties();
	private companyAddressDialog = new CompanyAddressDialogProperties();
    private companyService = new CompanyService();
    private currencyService = new CurrencyService();

	public get company() {
		return this.properties.company;
	}

    public created() {
        this.editorControl.createVisible = false;
        this.editorControl.deleteVisible = false;
        this.load();
    }

    public async load() {
        try {
            const r = await this.companyService.get({
                loadAddresses: true,
                loadAddressCity: true,
                loadAddressState: true,
                loadAddressCountry: true
            });
            if (!r.data) {
                throw new Error(this.$t("message.record-not-found").toString());
            }
            if (!r.data.addresses) {
                r.data.addresses = [];
            }
            
            var addresses = r.data.addresses;
            addresses = addresses.sort((lhs, rhs) => rhs.id - lhs.id);
            for (var i = 0; i < addresses.length; i++) {
                var tempId = Math.random();
                if (!addresses.find(x => x.tempId === tempId)) {
                    addresses[i].tempId = tempId;
                    addresses[i].full = AddressUtil.build(addresses[i]);
                } else {
                    i--;
                }
            }
            this.properties.company = r.data;
        } catch (e) {
            var m = ExceptionUtil.getMessage(e);
            AlertDialogProperties.error(this.alertDialog, null, m);
            this.alertDialog.tag = { command: "load-error" };
            this.alertDialog.visible = true;
        }
    }

    public async control(button: string) {
        if (button === "cancel") {
            this.cancel();
        } else if (button === "save") {
            await this.save();
        }
    }

    public cancel() {
        if (this.$data.saving) {
            return;
        }

        AlertDialogProperties.discard(this.alertDialog, null, null);
        this.alertDialog.tag = { command: "discard-changes" };
        this.alertDialog.visible = true;
    }

    public async save() {
        if (this.$data.saving) {
            return;
        }

        this.$data.saving = true;
        try {
            const r = await this.companyService.put(this.company, true);

            var addresses = r.data.addresses;
            addresses = addresses.sort((lhs, rhs) => rhs.id - lhs.id);
            for (var i = 0; i < addresses.length; i++) {
                var tempId = Math.random();
                if (!addresses.find(x => x.tempId === tempId)) {
                    addresses[i].tempId = tempId;
                    addresses[i].full = AddressUtil.build(addresses[i]);
                } else {
                    i--;
                }
            }

            AlertDialogProperties.saved(this.alertDialog, null, null);
            this.alertDialog.visible = true;
            this.load();
        } catch (e) {
			if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
				var m = ExceptionUtil.getMessage(e);
            	AlertDialogProperties.error(this.alertDialog, null, m);
            	this.alertDialog.visible = true;
			}
        } finally {
            this.$data.saving = false;
        }
    }

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag && tag.command === "discard-changes") {
            if (button === this.$t("text.yes") && !this.$data.saving) {
                await this.load();
            }
        }
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag && tag.command === "load-error") {
            await this.$router.push("/home");
        }
    }
}
